.app.dark {
  background-color: #111;
  // color: rgb(156, 156, 156);
  color: white;
  width: fit-content;

  .chartGrid {
    stroke: rgba(228, 228, 228, 0.219);
  }

  .table {
    background-color: #111;

    .tableCell {
      color: white;
    }
  }

  .css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar {
    color: white;
  }

  .table th {
    background-color: #111;
  }

  .user-table th {
    background-color: #111;
  }

  .user-entry {
    background-color: #111;
  }

  .user-entry:nth-child(even) {
    background-color: grey;
  }

  .table tbody tr:hover {
    background-color: grey;
    cursor: pointer;
  }


  .chart {
    flex: 4;
    -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
    padding: 10px;
    color: white;
    border-radius: 10px;
  
    .title {
      margin-bottom: 20px;
    }
  
    .chartGrid {
      stroke: rgb(228, 225, 225);
    }
  }

  .report__chart{
    color: white;
  }

  .new .newContainer .b-table .cellWithStatus.completed {
    color: white;
    background-color: rgba(0, 128, 0, 0.151);
  }

  .new .newContainer .b-table .cellWithStatus.active {
    color: rgb(90, 94, 187);
    background-color: rgba(202, 221, 202, 0.151);
  }

  .new .newContainer .b-table .cellWithStatus .assignButton {
    color: wheat;
  }

  .assignButton {
    color: wheat;
  }

  .reportTitle {
    color: white;
  }

  .title {
    color: white;
}

  .navbar {
    color: white;
    border-color: #333;

    .search {
      border-color: gray;
    }
  }

  .sidebar {
    background-color: #111;
    border-color: #333;

    .top {
      .logo {
        color: white;
      }
    }
    hr {
      border-color: #333;
    }

    ul {
      li {
        &:hover {
          background-color: #333;
        }

        .icon {
          color: #999;
        }
      }
    }
  }

  .datatable {
    .datagrid {
      color: white;
      border: none;

      .viewButton{
        color: azure;
      }
      .deleteButton{
        color: white;
      }
      .cellWithStatus {
        color: white;
        border: none;
      }
    }
  }

  input{
      background-color: transparent;
      color: white;
  }

  .css-pqjvzy-MuiSvgIcon-root-MuiSelect-icon {
    color: white;
  }

  .single .singleContainer .top .left .item .details .itemTitle {
    color: white;
  }

  .rs-icon {
    color: white;
  }
}
