.login-body{
    background-color: #2d2d39;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    background-repeat: no-repeat;
    background-size: cover;
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.login-background{
    width: 100%;
    height: 100%;
    margin-top: 30px;
}

.myoga-image{
    width: 500px;
    align-items: center;
    justify-content: center;
    height: auto;
}

.myoga-div{
    align-self: center;
}

.login-page{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: linear-gradient(55deg, #810555, #fff);
    width: 530px;
    // height: 100%;
    aspect-ratio: 1;
    border-radius: 10px;
    padding: 10px 80px;

}

.login-page::before{
    position: absolute;
     content: "";
     height: calc(100% - 8px);
    width: calc(100% - 8px);
     background-color: #111;
     border-radius: 10px;

 }

 .login-page header{
     position: absolute;
     top: 30px;
     display: flex;
     align-items: center;
 }

 .login-page header span{
     font-size: 22px;
    background: linear-gradient(90deg, #ff009d, #09b4c7);
    -webkit-background-clip: text;
    background-clip: text;
     color: transparent;
     font-weight: 800;

 }

 .logo{
    margin-top: 90px;
    position: relative;
    width: 200px;
 }

 .login-page form{
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
    margin-top: 30px;
    margin-bottom: 30px;

}

.login-page email,
.login-page password
{
    position: relative;  
}

.login-page form input{
    width: 100%;
    border: none;
    background-color: transparent !important;
    background: transparent;
    padding: 20px 10px 3px;
    border-bottom: 1px solid #810551;
    color: #fff;
}

.login-page form input:focus{
    outline: none;
    color: #fff;
}

.error{
    color: red;
    font-size: 12px;
    margin: 0 auto;
    text-align: center;
}

.login-page form button{
    background-color: #810555;
    border: none;
    border-radius: 20px;
    margin-top: 20px;
    padding: 10px;
    color: #fff;
    cursor: pointer;
}


.user-stat{
    color: #fff;
    font-size: 14px;
    margin: 0 auto;
    width: 500px;
}

.user-stat b {
    color: #aa0c6e;
    cursor: pointer;
}
button {
    display: flex;
    justify-content: center;
    align-items: center;
}
.spinner-btn:disabled {
    background-color: #7e7070;
    cursor: not-allowed;
}

.hidden {
    display: none;
}

.spinner {
    border: 2px solid #f3f3f3;
    border-top: 2px solid #3498db;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    animation: spin 0.8s linear infinite;
    margin-right: 10px;
}

.spinner-btn {
    background-color: #810555;
    border: none;
    border-radius: 20px;
    margin-top: 20px;
    padding: 10px;
    color: #fff;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.field-label {
    color: grey;
}

.field-label span{
    font-size: 15px;
}

.password {
    display: flex;
    align-items: baseline;
}

.custom_datepicker {
    width: 100%;
    border: none;
    background-color: transparent !important;
    background: transparent;
    padding: 20px 10px 3px;
    border-bottom: 1px solid #810551;
    color: #fff;
}

.rs-input-group.rs-input-group-inside {
    width: 100%;
    background-color: transparent !important;
    /* background-color: var(--rs-input-bg); */
}

.rs-input-group {
    border: none !important;
    /* border: 1px solid var(--rs-border-primary); */
}

.login-page button {
    background-color: #810555;
    border: none;
    border-radius: 20px;
    margin-top: 20px;
    padding: 10px;
    color: #fff;
    cursor: pointer;
    position: relative;
    padding-left: 20px;
    padding-right: 20px;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
/* Styles for small devices */
@media screen and (max-width: 600px) {
    .login-page {
        width: 90%; 
        padding: 10px 40px; 
    }

    .logo {
        margin-top: 50px;
    }

    /* Adjust font size for header span on smaller devices */
    .login-page header span {
        font-size: 18px;
    }
}

/* Additional styles for extra-small devices (e.g., smartphones) */
@media screen and (max-width: 400px) {
    .login-page {
        padding: 10px 20px; 
    }

    /* Adjust font size for header span on extra-small devices */
    .login-page header span {
        font-size: 16px;
    }
}
