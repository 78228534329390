// .modal-overlay {
//     position: fixed;
//     top: 0;
//     left: 0;
//     right: 0;
//     bottom: 0;
//     background-color: rgba(0, 0, 0, 0.5);
//     display: flex;
//     justify-content: center;
//     align-items: center;
//   }
  
//   .modal {
//     background-color: white;
//     padding: 20px;
//     width: 400px;
//     max-height: 80vh;
//     overflow-y: auto;
//     border-radius: 8px;
//     box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
//   }
  
  .terms-content {
    max-height: 60vh;
    overflow-y: auto;
  }
  
//   .modal-footer {
//     display: flex;
//     justify-content: space-between;
//   }
  
//   .cancel-button, .agree-button {
//     padding: 10px 20px;
//     border: none;
//     border-radius: 4px;
//     cursor: pointer;
//   }
  
//   .cancel-button {
//     background-color: #f44336;
//     color: white;
//   }
  
//   .agree-button {
//     background-color: #4CAF50;
//     color: white;
//   }

//   .agree-button:disabled, .cancel-button:disabled {
//     background-color: grey;
//     cursor: not-allowed;
//   }
  