.datatable {
  height: 600px;
  padding: 20px;
  
  .datatableTitle{
    width: 100%;
    font-size: 24px;
    color: gray;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .link{
      text-decoration: none;
      color: green;
      font-size: 16px;
      font-weight: 400;
      border: 1px solid green;
      padding: 5px;
      border-radius: 5px;
      cursor: pointer;
    }

        .b-table {
          -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
          box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
          padding: 10px;
          margin: 20px;
          display: flex;
        }
  }

  .cellWithImg {
    display: flex;
    align-items: center;

    .cellImg {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      object-fit: cover;
      margin-right: 20px;
    }
  }

  .cellWithStatus {
    padding: 5px;
    border-radius: 5px;

    &.active {
      background-color: rgba(0, 128, 0, 0.05);
      color: green;
    }
    &.pending {
      background-color: rgba(255, 217, 0, 0.05);
      color: goldenrod;
    }
    &.passive {
      background-color: rgba(255, 0, 0, 0.05);
      color: crimson;
    }
  }

  .cellAction {
    display: flex;
    align-items: center;
    gap: 15px;

    .viewButton {
      font-family: 'Times New Roman';
      padding: 2px 5px;
      border-radius: 5px;
      border: none;
      color: aliceblue;
      width: 60px;
      cursor: pointer;
    }

        .trackButton {
          font-family: 'Times New Roman';
          padding: 2px 5px;
          border-radius: 5px;
          border: none;
          color: aliceblue;
          background-color: green;
          width: 100px;
          cursor: pointer;
        }
    
    .deleteButton {
            font-family: 'Times New Roman';
      padding: 2px 5px;
      width: 60px;
      border-radius: 5px;
      color: rgb(255, 255, 255);
      border: none;
      cursor: pointer;
    }
    
    .verifyButton {
      font-family:'Times New Roman';
      padding: 2px 10px;
      border-radius: 5px;
      color: white;
      border: none;
      cursor: pointer;
    }
  }
}
