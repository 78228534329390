.list {
    cursor: pointer;
}
.table {
    border-collapse: collapse;
    font-size: 14px;
}

.table th,
.table td {
    border: 1px solid #ccc;
    padding: 8px;
    text-align: left;
}

.table th {
    background-color: #eee;
}

.table tbody tr:hover {
    background-color: #eee;
    cursor: pointer;
}

.selected {
    background-color: #007bff;
    color: #fff;
}