.new {
  width: 100%;
  height: 100%;
  display: flex;

  .newContainer {
    flex: 6;

    .top,
    .bottom, .b-table {
      -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
      box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
      padding: 10px;
      margin: 20px;
      // display: flex;

      .cellWithStatus{
        padding: 5px;
        border-radius: 5px;

        &.active{
            color: green;
            background-color: rgba(0, 128, 0, 0.151);
        }
        &.completed{
            color: green;
            background-color: rgba(0, 128, 0, 0.151);
        }
        &.inactive{
            color: crimson;
            background-color: rgba(255, 0, 0, 0.05);
        }
        &.Pending{
            color: goldenrod;
            background-color: rgba(189, 189, 3, 0.103);
        }
        &.pending{
            color: goldenrod;
            background-color: rgba(189, 189, 3, 0.103);
        }

        .assignButton{
            padding: 2px 5px;
            border-radius: 5px;
            color: darkblue;
            border: 1px solid rgba(0, 0, 139, 0.342);
            cursor: pointer;
        }
        .trackButton{
          padding: 2px 5px;
          border-radius: 5px;
          color: green;
          border: 1px solid rgba(7, 122, 12, 0.227);
          cursor: pointer;
      }
      }

      h1 {
        color: lightgray;
        font-size: 20px;
      }

      .left {
        flex: 1;
        text-align: center;

        img {
          width: 100px;
          height: 100px;
          border-radius: 50%;
          object-fit: cover;
        }
      }

      .right {
        flex: 2;

        form {
          display: flex;
          flex-wrap: wrap;
          gap: 30px;
          justify-content: space-around;

          .formInput {
            width: 40%;

            label {
              display: flex;
              align-items: center;
              gap: 10px;

              .icon {
                cursor: pointer;
              }
            }

            input {
              width: 100%;
              padding: 5px;
              border: none;
              border-bottom: 1px solid gray;
            }
          }

          button {
            width: 150px;
            padding: 10px;
            border: none;
            background-color: teal;
            color: white;
            font-weight: bold;
            cursor: pointer;
            margin-top: 10px;

            &:disabled{
              background-color: rgb(4, 230, 230);
              cursor: not-allowed;
            }
          }
        }
      }
    }
  }
}
.table {
  .cellWrapper {
    display: flex;
    align-items: center;

    .image {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      margin-right: 10px;
      object-fit: cover;
    }
  }

  .status {
    padding: 5px;
    border-radius: 5px;

    &.Approved {
      color: green;
      background-color: rgba(0, 128, 0, 0.151);
    }

    &.Pending {
      color: goldenrod;
      background-color: rgba(189, 189, 3, 0.103);
    }
  }

  
}
.top {
  justify-content: space-between;
}