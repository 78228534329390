
    button {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  
    .spinner-btn:disabled {
      background-color: #7e7070;
      cursor: not-allowed;
    }
  
    .hidden {
      display: none;
    }
  
    .spinner {
      border: 2px solid #f3f3f3;
      border-top: 2px solid #3498db;
      border-radius: 50%;
      width: 16px;
      height: 16px;
      animation: spin 0.8s linear infinite;
      margin-right: 10px;
    }
  
    .spinner-btn {
      background-color: #810555;
      border: none;
      border-radius: 20px;
      margin-top: 20px;
      padding: 10px;
      color: #fff;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  
    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
  
      100% {
        transform: rotate(360deg);
      }
    }

  // .cellAction {
  //   display: flex;
  //   align-items: center;
  //   gap: 15px;

  //   .viewButton {
  //     padding: 2px 5px;
  //     border-radius: 5px;
  //     color: darkblue;
  //     border: 1px dotted rgba(0, 0, 139, 0.596);
  //     cursor: pointer;
  //   }
    
  //   .deleteButton {
  //     padding: 2px 5px;
  //     border-radius: 5px;
  //     color: crimson;
  //     border: 1px dotted rgba(220, 20, 60, 0.6);
  //     cursor: pointer;
  //   }
  // }
.new {
  width: 100%;
  display: flex;

  .newContainer {
    flex: 6;

    .top,
    .bottom {
      -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
      box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
      padding: 10px;
      margin: 20px;
      display: flex;

      h1 {
        color: lightgray;
        font-size: 20px;
      }

      .left {
        flex: 1;
        text-align: center;

        img {
          width: 100px;
          height: 100px;
          border-radius: 50%;
          object-fit: cover;
        }
      }

      .right {
        flex: 2;

        form {
          display: flex;
          flex-wrap: wrap;
          gap: 30px;
          justify-content: space-around;

          .formInput {
            width: 40%;

            label {
              display: flex;
              align-items: center;
              gap: 10px;

              .icon {
                cursor: pointer;
              }
            }

            input {
              width: 100%;
              padding: 5px;
              border: none;
              border-bottom: 1px solid gray;
            }
          }

          button {
            width: 150px;
            padding: 10px;
            border: none;
            background-color: teal;
            color: white;
            font-weight: bold;
            cursor: pointer;
            margin-top: 10px;

            
          }
        }
      }
    }
  }
}
