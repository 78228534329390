.total-top{
    -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
        box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
        padding: 10px;
        margin: 20px;
}

.total-cal{
    display: flex;
    justify-content: space-between;
    width: auto;
}

.custom-datepicker {
    width: 100%;
    padding: 8px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

.chart-selects {
    width: 100%;
    font-size: 16px;
    border: 1px solid #3498db;
    border-radius: 4px;
    background-color: #ecf0f1;
    color: #141618;
    cursor: pointer;
    box-sizing: border-box;
}

/* Style for selected option */
.chart-selects option:checked {
    background-color: #3498db;
    color: #fff;
}