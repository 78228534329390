.profile-page {
    max-width: 1200px;
    margin: auto;
    padding: 20px;

    .profile-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;

        

        .header-left {
            display: flex;
            align-items: center;
            flex-grow: 1;

            

            .avatar {
                width: 80px;
                height: 80px;
                margin-right: 20px;
                

                img {
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;
                    object-fit: cover;
                }
            }

            .user-info {
                .name {
                    font-size: 24px;
                    font-weight: bold;
                    margin-bottom: 5px;
                }

                .email {
                    font-size: 16px;
                    color: #666;
                }
            }
        }

        .header-right {
            .button {
                padding: 10px 20px;
                font-size: 16px;
                background-color: #3f51b5;
                color: #fff;
                border: none;
                border-radius: 4px;
                cursor: pointer;

                &:hover {
                    background-color: #2c3e50;
                }
            }
        }
    }

    .profile-body {
        .card-container {
            display: flex;
            justify-content: space-between;

            .card {
                flex: 1;
                margin-right: 20px;
                margin-bottom: 20px;

                &:last-child {
                    margin-right: 0;
                }

                .MuiCardContent-root {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: center;
                    height: 100%;

                    h2 {
                        font-size: 20px;
                        margin-bottom: 10px;
                    }

                    p {
                        font-size: 16px;
                        margin-bottom: 5px;

                        strong {
                            font-weight: bold;
                        }
                    }
                }
            }
        }
    }
}

.single-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.single {
    display: flex;
    width: 100%;
    height: 100%;

    .singleContainer {
        flex: 6;
    }
}
.loader {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
}

.loader .lds-dual-ring {
    display: inline-block;
    width: 64px;
    height: 64px;
}

.loader .lds-dual-ring:after {
    content: " ";
    display: block;
    width: 46px;
    height: 46px;
    margin: 1px;
    border-radius: 50%;
    border: 5px solid #bdafaf;
    border-color: rgb(78, 78, 250) transparent rgb(78, 78, 250) transparent;
    animation: lds-dual-ring 1.2s linear infinite;
}

.documents-itemImg {
    width: 100px;
    height: 100px;
    border-radius: 20%;
    object-fit: cover;
    margin-right: 10px;
}

.documents-container {
    display: flex;
    flex-direction: row;
    margin-bottom: 50px;
}

.itemKey {
    margin-right: 10px;
    text-align: center;
}

@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}