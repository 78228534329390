.icon-active{
    color: rgb(5, 171, 5);
}

.icon-inactive{
    color: rgb(148, 147, 147);
}

.flex{
    flex-direction: row;
}