.navbar {
  height: 68px;
  border-bottom: 0.5px solid rgb(231, 228, 228);
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #555;

  .wrapper {
    width: 100%;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .search {
      display: flex;
      align-items: center;
      padding: 3px;

      input {
        border: none;
        outline: none;
        background: transparent;

        &::placeholder {
          font-size: 12px;
        }
      }
            .dropdown {
              position: absolute;
              top: 100%;
              left: 0;
              z-index: 1;
              width: 100%;
              max-height: 200px;
              overflow-y: auto;
              border: 1px solid #ccc;
              background-color: #fff;
              border-radius: 0 0 3px 3px;
              box-shadow: 0 4px 6px rgba(32, 33, 36, 0.28);
              padding: 5px 0;
      
              .dropdown-item {
                display: block;
                padding: 10px;
                text-decoration: none;
                color: #333;
      
                &:hover {
                  background-color: #f5f5f5;
                }
              }
            }
    }

    .items {
      display: flex;
      align-items: center;

      .item {
        display: flex;
        align-items: center;
        margin-right: 20px;
        position: relative;

        .icon {
          font-size: 20px;
        }

        .avatar {
          width: 30px;
          height: 30px;
          border-radius: 50%;
        }

        .counter {
          width: 15px;
          height: 15px;
          background-color: red;
          border-radius: 50%;
          color: white;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 10px;
          font-weight: bold;
          position: absolute;
          top: -5px;
          right: -5px;
        }
      }
    }
  }
}
