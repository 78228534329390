.reportTitle {
            width: 100%;
            font-size: 24px;
            color: gray;
            margin-bottom: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
    }

    .charts {
      // display: grid;
      // grid-template-columns: 1fr 1fr;
      // gap: 20px;
      padding: 5px 20px;
    }