.table {
    .cellWrapper {
        display: flex;
        align-items: center;

        .image {
            width: 32px;
            height: 32px;
            border-radius: 50%;
            margin-right: 10px;
            object-fit: cover;
        }
    }

    .status {
        padding: 5px;
        border-radius: 5px;
        color: green;
        background-color: rgba(0, 128, 0, 0.151);

        &.Approved {
            color: green;
            background-color: rgba(0, 128, 0, 0.151);
        }

        &.pending {
            color: goldenrod;
            background-color: rgba(189, 189, 3, 0.103);
        }
    }

    .assignDriver {
        padding: 2px 5px;
        border-radius: 5px;
        color: green;
        border: 1px dotted rgba(15, 12, 12, 0.6);
        cursor: pointer;
    }

      

}
 .no-data-message {
     position: absolute;
     top: 50%;
     left: 50%;
     transform: translate(-50%, -50%);
     display: flex;
     align-items: center;
     justify-content: center;
     font-size: 24px;
     color: #999;
     text-align: center;
     padding: 20px;
     background-color: #f7f7f7;
     border-radius: 10px;
     box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
     margin: 20px;
 }