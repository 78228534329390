.featured {
  flex: 2;
  -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
  box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
  padding: 10px;
  border-radius: 10px;

  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: gray;

    .title {
      font-size: 16px;
      font-weight: 500;      
    }
  }

  .bottom {
    padding: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px;
    

    .bottoms{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 150px;
    margin-top: 25px;
    }

    .featuredChart {
      width: 100px;
    }

    .total__holder{
      // -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
      // box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
      // padding: 10px;
      // border-radius: 50px;
      display: flex;
      text-align: center;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      isolation: isolate;
    }

    .place__holder {
      position: relative;
      display: flex;
      flex-direction: column;
      gap: 15px;
      width: 100px;
    }

        .user-table {
          width: 100%;
          border-collapse: collapse;
          border: 1px solid #ccc;
          margin-top: 20px;
        }
    
        .user-table th,
        .user-table td {
          padding: 10px;
          text-align: center;
          border: 1px solid #ccc;
        }
    
        .user-table th {
          background-color: #f2f2f2;
        }
    
        .user-entry {
          background-color: #fff;
        }
    
        .user-entry:nth-child(even) {
          background-color: #f2f2f2;
        }

    .title {
      font-weight: 500;
      color: gray;
      margin-bottom: 0;
      text-align: center;
    }

    .amount {
      font-size: 30px;
      text-align: center;
      justify-self: center;
    }

    .amounts{
      text-align: center;
      justify-self: center;
      font-size: 20px;
      margin: auto;
      overflow: hidden;
      white-space: nowrap;
      max-width: 100%;
      isolation: isolate;
    }

    .desc {
      font-weight: 300;
      font-size: 12px;
      color: gray;
      text-align: center;
    }

    .summary {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .item {
        text-align: center;

        .itemTitle {
          font-size: 14px;
          color: gray;
        }

        .itemResult {
          display: flex;
          align-items: center;
          margin-top: 10px;
          font-size: 14px;
          justify-content: center;

          &.positive {
            color: green;
          }

          &.negative {
            color: red;
          }
        }
      }
    }
  }

  .percent-paragraph{
    font-size: 12px;
  }
}
